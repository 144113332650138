<template>
  <!-- this is blue button -->
  <div>
    <b-button class="btn-role">{{ btnName }}</b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue"

export default {
  name: "TableButton",
  components: {
    BButton,
  },
 props: {
    btnName: {
      type: String,
      required: false,
      default: null
    },
  }
}
</script>

<style>
@import "./button.css";
</style>

